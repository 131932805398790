//base url
export const LOGIN_PAGE = 'https://dev-home.phillipsoutsourcing.net/login';
export const MAIN_MENU = 'https://dev-home.phillipsoutsourcing.net/home';
export const API_BASE_URL = 'https://dev-api.phillipsoutsourcing.net';
export const DOCUMENT_BASE_URL = 'https://s3.us-east-1.amazonaws.com/dev-api.phillipsoutsourcing.net';

//employees
export const MAP_UNIVERSITY = '/map-university';
export const MAP_COURSE = '/map-course';
export const MAP_DEGREE = '/map-degree';
export const MAP_CLASS_OBTAINED = '/map-class-obtained';
export const MAP_COMPLETION_DATE = '/map-completion-date';
export const TRANSFER_RECORDS = '/transfer-records';

export const CHECK_SUGGESTIONS = '/check-suggestions';
export const SEARCH_COURSES = '/search-courses';
export const SEARCH_DEGREES = '/search-degrees';
export const DEGREE_CLASSES = '/degree-classes';
export const SEARCH_UNIVERSITY = '/search-universities';
export const WORK_EXPERIENCES = '/work-experiences';
export const IMPORT_WORK_EXPERIENCE_FILE = '/import-work-experience-file';
export const IMPORT_QUALIFICATION_FILE = '/import-qualification-file';
export const IMPORT_EDUCATION_FILE = '/import-education-file';
export const IMPORT_DEPENDENT_FILE = '/import-dependent-file';
export const IMPORT_GUARANTOR_FILE = '/import-guarantor-file';
export const IMPORT_BENEFIT_FILE = '/import-benefit-file';
export const IMPORT_NEXT_KIN_FILE = '/import-next-kin-file';
export const PROF_QUALIFICATION = '/qualifications';
export const EXPORT_WORK_EXPERIENCE_TEMPLATE = '/export-work-experience-template';
export const EXPORT_QUALIFICATION_TEMPLATE = '/export-qualification-template';
export const EXPORT_DEPENDENT_TEMPLATE = '/export-dependent-template';
export const EXPORT_GUARANTOR_TEMPLATE = '/export-guarantor-template';
export const EXPORT_BENEFIT_TEMPLATE = '/export-benefit-template';
export const EXPORT_NEXT_KIN_TEMPLATE = '/export-next-kin-template';
export const EXPORT_EDUCATION_TEMPLATE = '/export-education-template';
export const EDU_RECORDS = '/educational-records';
export const RAW_EDU_RECORDS = '/raw-education';
export const EMPLOYEE_URL = '/employees';
export const REMOVE_SELECTED_EMPLOYEES = '/remove-candidates-profile';
export const REMOVE_SELECTED_FILES = '/remove-selected-files';
export const SEARCH_EMPLOYEE_LISTS = '/search-employee-list';
export const EMPLOYEE_FILES = '/employee-files';
export const SCAN_EMPLOYEE_LIST = '/scan-employee-list';
export const SCAN_EMPLOYEES = '/scan-employees';
export const SAVE_EMPLOYEE_LIST = '/save-employee-list';
export const SAVE_EMPLOYEES = '/save-employees';
export const SAVE_PROFILE_PHOTO = '/save-photos';
export const GET_EMPLOYEE_FILES = '/get-employee-files';
export const SEARCH_EMPLOYEE_FILES = '/search-employee-files';
export const ONBOARDING_DOCUMENTS = '/onboarding-documents';
export const SEARCH_COMPANY_EMPLOYEE = '/search-company-employee';
export const GET_LEAVE_APPLICATIONS = '/leave-applications';
export const APPROVE_LEAVE_REQUESTS = '/approve-leave-requests';
export const REJECT_LEAVE_REQUESTS = '/reject-leave-requests';
export const EXPORT_EMPLOYEE_TEMPLATE = '/export-employee-template';
export const EXPORT_PHOTO_TEMPLATE = '/export-photo-template';
export const EXPORT_DEPARTMENT_TEMPLATE = '/export-department-template';


//Company
export const NEXT_KINS = '/next-kins';
export const GUARANTORS = '/guarantors';
export const BENEFICIARIES = '/beneficiaries';
export const DEPENDENTS = '/dependents';
export const COMPANIES_URL = '/companies';
export const COMPANIES_ADD_USERS = '/companies/users';
export const MAKE_USER_COMPANY_REPRESENTATIVE = '/make-representative';
export const DEFAULT_COMPANY_URL = '/default-companies';
export const DASHBOARD_STATISTICS = '/dashboard-statistics';
export const GET_COMPANY_LOGS = '/company-logs';
export const JOB_ROLES_URL = '/job-roles';
export const DEPARTMENT_ROLES = '/department-roles';
export const SCAN_DEPARTMENT_FILE = '/scan-department-file';
export const SAVE_DEPARTMENT_FILE = '/save-department-file';


export const SEARCH_COMPANY_USERS = '/search-company-users';
export const SEARCH_COMPANY = '/search-company';
export const SEARCH_COMPANY_WORKDAYS = '/search-company-workdays';
export const SEARCH_COMPANY_REPORT_TITLE = '/search-company-report-title';
export const SAVE_WORK_DAYS = '/save-company-workdays';
export const SAVE_REPORT_TITLE = '/save-report-title';


//Accounts
export const ACCOUNT_CATEGORIES_URL = '/account-categories';
export const SEARCH_SUB_ACCOUNTS = '/search-sub-accounts';
export const ACCOUNT_URL = '/accounts';
export const ACCOUNT_SUMMARY = '/account-summaries';
export const ASSIGN_ACCOUNT_SUMMARY = '/assign-account-summary';
export const SEARCH_ACCOUNTS = '/search-accounts';

//Payroll and Items
export const ITEMS_URL = '/items';
export const ITEM_TYPE_URL = '/item-types';
export const SEARCH_ITEMS = '/search-items';
export const SELECTED_PAYROLL_ITEMS = '/selected-payroll-items';
export const SAVE_SELECTED_PAYROLL_ITEMS = '/save-selected-payroll-items';


//users
export const SEARCH_USERS = '/search-users';

//general urls
export const INDUSTRIES_URL = '/industries';
export const BUSINESS_TYPES_URL = '/business-types';
export const COUNTRIES_URL = '/countries';

//data urls
export const EXPORT_ACCOUNT_TEMPLATE = '/export-accounts-template';
export const EXPORT_ITEM_TEMPLATE = '/export-items-template';
export const SCAN_ACCOUNT_FILE = '/scan-accounts-files';
export const SCAN_ITEM_FILE = '/scan-items-files';
export const IMPORT_ACCOUNT_FILE = '/import-accounts-files';
export const IMPORT_ITEM_FILE = '/import-items-files';
export const EXPORT_LEAVE_APPLICATIONS_TEMPLATE = '/export-leave-applications-template';
export const IMPORT_LEAVE_APPLICATIONS_FILE = '/import-leave-applications-files';
export const SCAN_LEAVE_APPLICATIONS_FILE = '/scan-leave-applications-files';

//system settings
export const GET_BUSINESS_PERIOD = '/get-business-period';
export const SAVE_BUSINESS_PERIOD = '/save-business-period';
export const GET_TRACKING_ACCOUNT_SETTINGS = '/get-tracking-accounts';
export const SAVE_TRACKING_ACCOUNT_SETTINGS = '/save-tracking-accounts';
export const GET_PAYROLL_COMPUTATIONS = '/get-payroll-computations';
export const REMOVE_COMPUTATION = '/remove-computations';
export const TOGGLE_COMPUTATION = '/toggle-computation';
export const GET_COMPUTATIONS_VARIABLES = '/get-computations-variables';
export const SAVE_PAYROLL_COMPUTATIONS = '/save-payroll-computations';
export const GET_ACTIVE_FORMULAS = '/get-active-formulas';
export const ADD_PAYROLL_APPROVER = '/add-payroll-approver';
export const ADD_PAYROLL_NOTIFIER = '/add-payroll-notifier';
export const ADD_RECRUITMENT_USER = '/add-recruitment-user';
export const ADD_VARIATION_NOTIFIER = '/add-variation-notifier';
export const ADD_DOCUMENTATION_NOTIFIER = '/add-documentation-notifier';
export const ADD_CASES_RECIPIENT = '/add-cases-recipient';
export const ADD_PAYMENT_NOTIFIER = '/add-payment-notifier';
export const ADD_INIT_PAYMENT_NOTIFIER = '/add-init-payment-notifier';
export const ADD_BUSINESS_DEVELOPMENT_NOTIFIER = '/add-business-development';
export const ADD_VARIATION_APPROVER = '/add-variation-approver';
export const GET_PAYROLL_APPROVERS = '/get-payroll-approvers';
export const GET_PAYROLL_NOTIFIERS = '/get-payroll-notifiers';
export const GET_RECRUITMENT_USERS = '/get-recruitment-users';
export const GET_VARIATION_NOTIFIERS = '/get-variation-notifiers';
export const GET_DOCUMENTATION_NOTIFIERS = '/get-documentation-notifiers';
export const GET_CASES_RECIPIENTS = '/get-cases-recipients';
export const GET_PAYMENT_NOTIFIERS = '/get-payment-notifiers';
export const GET_INIT_PAYMENT_NOTIFIERS = '/get-init-payment-notifiers';
export const GET_BUSINESS_DEVELOPMENT_NOTIFIERS = '/get-business-developments';
export const GET_VARIATION_APPROVERS = '/get-variation-approvers';
export const SAVE_APPROVER_ORDER = '/save-approver-order';
export const SAVE_VARIATION_NOTIFIER_ORDER = '/save-variation-notifier-order';
export const SAVE_DOCUMENTATION_NOTIFIER_ORDER = '/save-documentation-notifier-order';
export const SAVE_CASES_RECIPIENT_ORDER = '/save-cases-recipient-order';
export const SAVE_PAYROLL_NOTIFICATION_ORDER = '/save-payroll-notifier-order';
export const SAVE_RECRUITMENT_ORDER = '/save-recruitment-order';
export const SAVE_PAYMENT_NOTIFICATION_ORDER = '/save-payment-notifier-order';
export const SAVE_INIT_PAYMENT_NOTIFICATION_ORDER = '/save-init-payment-notifier-order';
export const SAVE_BUSINESS_DEVELOPMENT_NOTIFICATION_ORDER = '/save-business-development-order';
export const SAVE_NOTIFIER_ORDER = '/save-notifier-order';
export const SAVE_VARIATION_APPROVER_ORDER = '/save-variation-approver-order';
export const REMOVE_VARIATION_APPROVER = '/remove-variation-approver';
export const REMOVE_PAYROLL_APPROVER = '/remove-payroll-approver';
export const REMOVE_PAYROLL_NOTIFIER = '/remove-payroll-notifier';
export const REMOVE_RECRUITMENT_USER = '/remove-recruitment-user';
export const REMOVE_VARIATION_NOTIFIER = '/remove-variation-notifier';
export const REMOVE_DOCUMENTATION_NOTIFIER = '/remove-documentation-notifier';
export const REMOVE_CASES_RECIPIENTS = '/remove-cases-recipients';
export const REMOVE_PAYMENT_NOTIFIER = '/remove-payment-notifier';
export const REMOVE_INIT_PAYMENT_NOTIFIER = '/remove-init-payment-notifier';
export const REMOVE_BUSINESS_DEVELOPMENT_NOTIFIER = '/remove-business-developments';

export const GET_TAXES = '/get-taxes';
export const REMOVE_TAX_ITEM = '/remove-tax-item';
export const GET_TAX_ITEMS = '/get-tax-items';
export const ADD_TAX_ITEM = '/add-tax-item';


/*=====================================
|   Administrations Database Actions
|=====================================
|
|
*/


export const GET_DEFAULT_EMPLOYEES = '/get-default-employees';
export const GET_USER_ROLES = '/get-user-roles';
export const SAVE_EMPLOYEE_PERMISSION = '/save-employee-permissions';
export const SET_AS_DEFAULT_COMPANY = '/set-default-company';
export const SAVE_A_ROLE = '/save-user-role';
export const GET_APPLICATIONS = '/get-applications';
export const GET_APPLICATION_AUTHORIZATION = '/get-app-authorization';
export const SAVE_APPLICATION_AUTHORIZATION = '/save-app-authorization';
export const GET_USER_AUTHORIZATION = '/get-user-authorization';
export const GET_USER_COMPANIES = '/get-user-companies';
export const ADD_USER_COMPANY = '/add-user-company';
export const REMOVE_USER_COMPANY = '/remove-user-company';
export const GET_MY_IP = '/get-my-ip';
export const GET_RESTRICTED_IPS = '/get-restricted-ips';
export const REMOVE_IPS = '/remove-ips';
export const ACTIVATE_IPS = '/activate-ips';
export const DEACTIVATE_IPS = '/deactivate-ips';
export const ADD_IP = '/add-ip';
export const EXCLUDE_USER_IP = '/exclude-user-attendance';
export const GET_IP_EXCLUSIONS = '/get-ip-exclusions';

export const HOLIDAYS = '/holidays';
export const GRP_ACTIVATION = '/grp-activation';

